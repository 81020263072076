enum Color {
  PRIMARY_100 = "#001529",
  NEUTRAL_100 = "#141414",
  NEUTRAL_400 = "#f6f6f6",
  NEUTRAL_900 = "#d9d9d9",

  ACCENT_400 = "#486BB3",
  ACCENT_900 = "#b7e3fa",
}

export default Color;
